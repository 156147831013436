import React from 'react';
import SEO from '../components/seo';
import { Box, ButtonBase, Container, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(10, 0),
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#F2F2F2',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7, 0),
    },
  },
  flexContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  spacer: {
    padding: theme.spacing(7, 0),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  iconButton: {
    height: 50,
    width: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  buttonsContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
}));

export default function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <>
      <SEO title="Privacy Policy" />
      <section className={classes.root}>
        <Container>
          <Typography variant="h1" align="center" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="subtitle" paragraph align="center">
            Last updated: February 25, 2021
          </Typography>
          <Typography variant="h2" paragraph align="center">
            FamPro, Inc. (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates https://famguru.app/ and the
            FamPro mobile application (the &quot;Service&quot;). This page informs you of our policies regarding the
            collection, use and disclosure of Personal Information when you use our Service. We will not use or share
            your information with anyone except as described in this Privacy Policy. We use your Personal Information
            for providing and improving the Service. By using the Service, you agree to the collection and use of
            information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in
            this Privacy Policy have the same meanings as in our Terms and Conditions.
          </Typography>
          <Typography variant="h1" align="center" gutterBottom>
            Information Collection And Use
          </Typography>
          <Typography variant="h2" paragraph align="center">
            While using our Service, we may ask you to provide us with certain personally identifiable information that
            can be used to contact or identify you. Personally identifiable information (&quot;Personal
            Information&quot;) may include, but is not limited to: ● Email ● Name ● Telephone ● Address ● Others
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Log Data
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We may also collect information that your browser sends whenever you visit our Service or when you access
            the Service by or through a mobile device (&quot;Log Data&quot;). This Log Data may include information such
            as your computer&#39;s Internet Protocol (&quot;IP&quot;) address, browser type, browser version, the pages
            of our Service that you visit, the time and date of your visit, the time spent on those pages and other
            statistics. When you access the Service by or through a mobile device, this Log Data may include information
            such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile
            device, your mobile operating system, the type of mobile Internet browser you use and other statistics. In
            addition, we may use third party services such as Google Analytics that collect, monitor and analyze this
            type of information in order to increase our Service&#39;s functionality. These third party service
            providers have their own privacy policies addressing how they use such information.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Location information
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We may use and store information about your location, if you give us permission to do so. We use this
            information to provide features of our Service, to improve and customize our Service. You can enable or
            disable location services when you use our Service at anytime, through your mobile device settings.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Cookies
          </Typography>
          <Typography variant="h2" paragraph align="center">
            Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are
            sent to your browser from a web site and stored on your computer&#39;s hard drive. We use
            &quot;cookies&quot; to collect information. You can instruct your browser to refuse all cookies or to
            indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some
            portions of our Service.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Behavioral Remarketing
          </Typography>
          <Typography variant="h2" paragraph align="center">
            FamPro, Inc. uses remarketing services to advertise on third party web sites to you after you visited our
            Service. We, and our third party vendors, use cookies to inform, optimize and serve ads based on your past
            visits to our Service. Google Google AdWords remarketing service is provided by Google Inc. You can opt-out
            of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the
            Google Ads Settings page: https://adssettings.google.com/ Google also recommends installing the Google
            Analytics Opt-out Browser Add-on - https://tools.google.com/dlpage/gaoptout - for your web browser. Google
            Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being
            collected and used by Google Analytics. For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page: https://policies.google.com/privacy Facebook Facebook
            remarketing service is provided by Facebook Inc. You can learn more about interest-based advertising from
            Facebook by visiting this page: https://www.facebook.com/help/164968693837950 To opt-out from Facebook&#39;s
            interest-based ads follow these instructions from Facebook: https://www.facebook.com/help/568137493302217
            Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the
            Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through
            the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising
            Alliance of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital Advertising
            Alliance in Europe http://www.youronlinechoices.eu/, or opt- out using your mobile device settings. For more
            information on the privacy practices of Facebook, please visit Facebook&#39;s Data Policy:
            https://www.facebook.com/privacy/explanation
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Do Not Track Disclosure
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We support Do Not Track (&quot;DNT&quot;). Do Not Track is a preference you can set in your web browser to
            inform websites that you do not want to be tracked. You can enable or disable Do Not Track by visiting the
            Preferences or Settings page of your web browser.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Service Providers
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We may employ third party companies and individuals to facilitate our Service, to provide the Service on our
            behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These
            third parties have access to your Personal Information only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Communications
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We may use your Personal Information to contact you with newsletters, marketing or promotional materials and
            other information that may be of interest to you. You may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link or instructions provided in any email we send or by
            contacting us.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Compliance With Laws
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We will disclose your Personal Information where required to do so by law or subpoena or if we believe that
            such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect
            the security or integrity of our Service.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Service Providers
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We may employ third party companies and individuals to facilitate our Service, to provide the Service on our
            behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These
            third parties have access to your Personal Information only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Communications
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We may use your Personal Information to contact you with newsletters, marketing or promotional materials and
            other information that may be of interest to you. You may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link or instructions provided in any email we send or by
            contacting us.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Compliance With Laws
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We will disclose your Personal Information where required to do so by law or subpoena or if we believe that
            such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect
            the security or integrity of our Service.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Business Transaction
          </Typography>
          <Typography variant="h2" paragraph align="center">
            If FamPro, Inc. is involved in a merger, acquisition or asset sale, your Personal Information may be
            transferred. We will provide notice before your Personal Information is transferred and becomes subject to a
            different Privacy Policy.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Security
          </Typography>
          <Typography variant="h2" paragraph align="center">
            The security of your Personal Information is important to us, but remember that no method of transmission
            over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Links To Other Sites
          </Typography>
          <Typography variant="h2" paragraph align="center">
            Our Service may contain links to other sites that are not operated by us. If you click on a third party
            link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy
            of every site you visit. We have no control over, and assume no responsibility for the content, privacy
            policies or practices of any third party sites or services.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Children's Privacy
          </Typography>
          <Typography variant="h2" paragraph align="center">
            Our Service does not address anyone under the age of 13 ("Children"). We do not knowingly collect personally
            identifiable information from children under 13. If you are a parent or guardian and you are aware that your
            Children has provided us with Personal Information, please contact us. If we become aware that we have
            collected Personal Information from a child under age 13 without verification of parental consent, we take
            steps to remove that information from our servers.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Changes To This Privacy Policy
          </Typography>
          <Typography variant="h2" paragraph align="center">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            Changes to this Privacy Policy are effective when they are posted on this page.
          </Typography>

          <Typography variant="h1" align="center" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="h2" paragraph align="center">
            If you have any questions about this Privacy Policy, please contact us.
          </Typography>
        </Container>
      </section>
    </>
  );
}
